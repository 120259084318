.section {
  @include mobile {
    padding-left: 0;
    padding-right: 0;

    > .columns {
      margin: 0;

      > .column-sketch {
        padding: 0;
        display: flex;
        overflow-x: scroll;
      }
    }
  }
}

.sketch {
  padding: 80px 20px 20px;
  background: url("../../public/sketch.png") repeat-x 0 0,
    linear-gradient(white, white) no-repeat 0 200px;
  box-shadow: 20px 40px 40px 5px rgba(0, 0, 0, 0.4);

  @include until($desktop) {
    padding-right: 0;
    padding-left: 0;
    padding-bottom: 0;

    margin: $column-gap;
  }

  table {
    background-color: transparent;
  }

  .table-wrapper {
    border: $table-cell-border;
    border-width: 5px;

    @include tablet {
      overflow-x: scroll;
      overflow-y: hidden;
    }
  }

  td {
    border-top-width: 3px;
    border-left-width: 3px;

    &:first-child {
      border-left-width: 0;
      white-space: nowrap;
    }

    &:not(:first-child) {
      font-size: 1.4em;
      text-align: center;
      min-width: 100px;
      line-height: 0.5;

      & .has-text-cursive {
        font-size: 1.3em;
      }
    }
  }

  td,
  th {
    vertical-align: middle;

    &:first-child {
      text-align: left;
      position: sticky;
      left: 0;
      background-color: white;
      z-index: 5;
    }
  }

  @include until($desktop) {
    td,
    th {
      padding: 0.15em 0.5em;
    }
  }
}

.table-clear {
  height: 40px;
  border-left: none !important;
  border-right: none !important;

  tr:hover {
    background-color: transparent !important;
  }
}

.player-input {
  min-width: 110px;
  padding-right: 1.5em !important;

  &[disabled] {
    color: $text;
    background-color: white;
  }
}
