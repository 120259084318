@font-face {
  font-family: "Pacifico";
  src: url("../fonts/Pacifico-Regular.woff2") format("woff2"),
    url("../fonts/Pacifico-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

$container-max-width: 1180px;

$input-focus-box-shadow-size: none;
$input-shadow: none;
$input-hover-border-color: none;

$button-border-color: none;
$button-hover-border-color: none;
$button-padding-vertical: 1em;

$size-1: 4.5rem;

// https://colorhunt.co/palette/f9e4d4d67d3e9c0f48470d21
$primary: #d67d3e;
$scheme-main: lighten($primary, 37%);
$scheme-main-bis: darken($scheme-main, 5%);
$text: #1d1d1d;
$danger: #9c0f48;
$border: $scheme-main-bis;
$green: #27ae60;

$link: #470d21;
$link-hover: darken(hsl(0, 0%, 29%), 30%);

$navbar-item-hover-background-color: none;
$navbar-item-img-max-height: 6rem;

:export {
  primary: $primary;
}
