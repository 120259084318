:root {
  --dice-color: #{$primary};
  --glow-color: #{$primary};
}

html {
  height: 100%;
}

body {
  background: url("../../public/wallpaper.jpeg");
  min-height: 100%;

  @include until($desktop) {
    background-size: contain;
  }
}

.navbar {
  position: absolute;
  background-color: transparent;
  width: 100%;
  display: flex;
  justify-content: flex-end;

  @include mobile {
    position: static;
  }

  > * {
    margin: 10px;
  }
}

.status-indicator {
  width: 9px;
  height: 9px;
  background-color: $green;
  margin-left: 10px;
  border-radius: 50%;

  --glow-color: #{$green};

  &.is-glow:after {
    border-radius: 50%;
  }
}

.settings-item {
  text-align: center;
  min-height: 130px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include tablet {
    &:nth-child(3n + 1) {
      background-color: $primary-light;
    }
  }

  @include mobile {
    &:nth-child(2n + 2) {
      background-color: $primary-light;
    }
  }

  small {
    width: 100%;
    display: inline-block;
    color: $grey;
    margin-top: 20px;
  }
}

[data-tooltip]:hover:before {
  background-color: rgba(74, 74, 74, 0.7);
}

.has-text-cursive {
  font-family: "Pacifico", cursive;
  text-rendering: optimizeSpeed;
}

.is-reverse-mobile {
  @include mobile {
    flex-direction: column-reverse;
  }
}

.is-glow {
  position: relative;
  z-index: 3;
}

.is-glow:after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  border-radius: 3px;
  animation-name: gradient-shadow;
  animation-timing-function: ease;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}

@keyframes gradient-shadow {
  0% {
    box-shadow: 0 0 17px 3px var(--glow-color), 0 0 4px 2px var(--glow-color);
  }
  50% {
    box-shadow: 0 0 17px 3px transparent, 0 0 4px 2px transparent;
  }
  100% {
    box-shadow: 0 0 17px 3px var(--glow-color), 0 0 4px 2px var(--glow-color);
  }
}
