$grey-background: #212121;

.roller--board__box {
  padding: 10px;
  background-image: url("../../public/metal.jpg");
  border-radius: 100px;
  max-width: 700px;
  aspect-ratio: 1;
  box-shadow: 0 0 40px 10px rgba(0, 0, 0, 0.8);

  @include tablet {
    margin: 3rem auto;
  }

  @include until($desktop) {
    padding: 5px;
    border-radius: 40px;
  }
}

.roller--board {
  aspect-ratio: 1;
  padding: 50px;
  background-image: url("../../public/plastic.png");
  background-color: $grey-background;
  border-radius: 100px;

  @include until($desktop) {
    padding: 20px;
    border-radius: 40px;
  }
}

.roller--carpet {
  aspect-ratio: 1;
  background-color: $green;
  background-image: url("../../public/texture.png");
  border-radius: 80px;
  box-shadow: inset 0 0 30px rgba(0, 0, 0, 0.8);

  @include until($desktop) {
    border-radius: 30px;
    background-size: contain;
  }
}

.roll-button {
  width: 90px;
  height: 90px;
  margin-left: 15px;
  font-size: 1.5rem;
  background-image: url("../../public/plastic.png"),
    linear-gradient($grey-background, black);

  @include until($desktop) {
    width: 60px;
    height: 60px;
    font-size: 1rem;
  }
}

.roll-info {
  display: flex;
  transition: transform 0.5s;
  justify-content: center;
  align-items: center;

  &.roll-info--up {
    transform: translateY(-160px);

    @include mobile {
      transform: translateY(0);
    }

    @include tablet-only {
      transform: translateY(-100px);
    }
  }
}

.dices-pedestal {
  border-radius: 40px 40px 15px 15px;
  background-image: url("../../public/plastic.png"),
    linear-gradient($grey-background, black);
  padding: 15px;
  width: 100%;
  max-width: 550px;
  display: flex;
  justify-content: space-evenly;
  box-shadow: 0 -10px 40px 5px rgba(0, 0, 0, 0.8);

  &--item {
    background-color: darken($grey-background, 5%);
    padding: 15px;
    border-radius: 15px;
    box-shadow: inset 0 0 30px rgba(0, 0, 0, 0.8);

    @include until($desktop) {
      padding: 7px;
    }
  }

  @include until($desktop) {
    padding: 10px;
    border-radius: 20px 20px 7px 7px;
  }
}
