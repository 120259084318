.dice {
  width: 60px;
  height: 60px;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 5px;
  border: 5px transparent solid;

  @include until($desktop) {
    width: 40px;
    height: 40px;
  }

  &--selected {
    border-color: white;
  }

  &--small {
    width: 30px;
    height: 30px;
    border-width: 2px;
  }

  &-1 {
    background-image: url("../components/roller/assets/1.png");
    background-color: var(--dice-color);
  }

  &-2 {
    background-image: url("../components/roller/assets/2.png");
    background-color: var(--dice-color);
  }

  &-3 {
    background-image: url("../components/roller/assets/3.png");
    background-color: var(--dice-color);
  }

  &-4 {
    background-image: url("../components/roller/assets/4.png");
    background-color: var(--dice-color);
  }

  &-5 {
    background-image: url("../components/roller/assets/5.png");
    background-color: var(--dice-color);
  }

  &-6 {
    background-image: url("../components/roller/assets/6.png");
    background-color: var(--dice-color);
  }
}
